import type { DeviceOrientation, Viewport } from "@website/types";

interface ElementWithViewport extends HTMLElement {
  getViewportElement(): Element;
}
type InteractionObserverCallback = () => void;

export function getOrientation(): DeviceOrientation {
  return (
    "matchMedia" in window
      ? window.matchMedia("(orientation: portrait)").matches
      : document.documentElement.clientWidth <=
        document.documentElement.clientHeight
  )
    ? "portrait"
    : "landscape";
}

export function getViewport(): Viewport {
  const viewport = {
    xs: 0,
    md: 768,
    lg: 1024,
    xl: 1440,
  };

  function getOrientation(): "landscape" | "portrait" {
    if (window.screen && window.screen.orientation) {
      return window.screen.orientation.type.includes("landscape")
        ? "landscape"
        : "portrait";
    }
    // this is a fallback for Safari 16.0-16.3
    // please remove the following three lines after Safari 18 is released (since we support the last 2 major versions)
    if ("orientation" in window) {
      return Math.abs(window.orientation) === 90 ? "landscape" : "portrait";
    }

    return (window as Window).innerWidth > (window as Window).innerHeight
      ? "landscape"
      : "portrait";
  }

  function isMobile(): boolean {
    const height: number = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0,
    );
    const width: number = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    );

    return height < viewport.lg && width < viewport.lg;
  }

  const viewportWidth: number =
    isMobile() && getOrientation() === "landscape"
      ? Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      : Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  return viewportWidth <= viewport.md
    ? "mobile"
    : viewportWidth <= viewport.lg
      ? "tablet"
      : viewportWidth <= viewport.xl
        ? "desktop"
        : "widescreen";
}

export class InteractionObserver {
  #callback: InteractionObserverCallback;
  #events: string[] = ["click", "keydown", "mousemove", "scroll", "touchstart"];
  #targets: Element[] = [];

  constructor(callback: InteractionObserverCallback) {
    this.#callback = callback;
  }

  #handleEvents = () => {
    this.#callback();
  };

  #removeEventListeners(target: Element) {
    for (const event of this.#events) {
      target.removeEventListener(event, this.#handleEvents);
    }
  }

  observe(target: Element) {
    for (const event of this.#events) {
      target.addEventListener(event, this.#handleEvents);
    }
    this.#targets.push(target);
  }

  unobserve(target: Element) {
    const index = this.#targets.indexOf(target);
    if (index === -1) return;
    this.#removeEventListeners(target);
    this.#targets.splice(index, 1);
  }

  disconnect() {
    for (const target of this.#targets) {
      this.#removeEventListeners(target);
    }
  }
}

export function isElementWithViewport(
  element: Element | null,
): element is ElementWithViewport {
  return (
    element instanceof Element &&
    "getViewportElement" in element &&
    typeof element.getViewportElement === "function"
  );
}

/**
 * Checks if the website is opened in a native app.
 * This is determined by the `viewMode` query parameter or the user agent (legacy app).
 */
export function isInApp(search: string, userAgent: string): boolean {
  const params = new URLSearchParams(search);
  const app = params.get("viewMode") === "app";
  const legacyApp = /(app|pwa)_(and|ios)/.test(userAgent);
  return app || legacyApp;
}

export function safeJsonParse<T>(
  text: string,
  typeGuard: (obj: any) => obj is T,
): T | undefined {
  try {
    const parsed = JSON.parse(text);
    return typeGuard(parsed) ? (parsed as T) : undefined;
  } catch (error) {
    console.error("Failed to parse JSON ", error);
    return undefined;
  }
}

export function storageAvailable(
  type: "localStorage" | "sessionStorage",
): boolean {
  try {
    const storage = window[type];
    const testKey = "__test__";
    storage.setItem(testKey, testKey);
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    console.error(`Storage checking for '${type}' failed `, error);
    return false;
  }
}

export class TimeoutError extends Error {
  timeout: number;

  constructor(message: string, timeout: number) {
    super(message);
    this.name = "TimeoutError";
    this.timeout = timeout;
  }
}

export async function waitUntil(
  predicate: () => boolean,
  options: { timeout?: number; interval?: number },
) {
  const timeout: number = options.timeout ?? 5000;
  const interval: number = options.interval ?? 100;
  let duration = 0;

  return new Promise<{ duration: number }>((resolve, reject) => {
    if (predicate()) {
      resolve({ duration });
      return;
    }

    const intervalId = window.setInterval(() => {
      duration += interval;
      if (predicate()) {
        clearInterval(intervalId);
        resolve({ duration });
      }
    }, interval);

    window.setTimeout(() => {
      clearInterval(intervalId);
      reject(new TimeoutError("Operation timed out", timeout));
    }, timeout);
  });
}

export const supportsResizeObserver = "ResizeObserver" in window;

// Polyfills "scrollend" event in safari
export function polyfillScrollendEvent(element: Element) {
  const scrollendEvent = new Event("scrollend");
  let scrollEndTimer: number;

  element.addEventListener("scroll", () => {
    window.clearTimeout(scrollEndTimer);
    scrollEndTimer = window.setTimeout(
      () => element.dispatchEvent(scrollendEvent),
      100,
    );
  });
}

/**
 * Load resources like CSS or JS files into the webpage.
 *
 * @param url - Resource URL (CSS, JS) to load
 * @param properties - Additional element properties e.g. { class: "button" }
 * @param text - Element inner text
 * @returns Returns with HTMLElement or Error
 */
export function loadResource(
  url: string,
  properties: Record<string, any> = {},
  text = "",
): Promise<HTMLElement> {
  if (!url) {
    return Promise.reject(
      new Error(
        'Missing "url" parameter, try something like `loadResource("https://code.jquery.com/jquery-3.3.1.min.js")`.',
      ),
    );
  }

  const filetype = /\.(css|js)/.exec(url);
  if (filetype === null) {
    return Promise.reject(
      new Error('Unknown file type, try to load ".css" or ".js" files.'),
    );
  }

  // Define a more specific type for the configuration object
  type ResourceType = "css" | "js";
  type ElementProperties = {
    href?: string;
    rel?: string;
    async?: boolean;
    src?: string;
    [key: string]: any; // Allow additional properties as needed
  };

  const configuration: Record<
    ResourceType,
    { element: string; properties: ElementProperties }
  > = {
    css: { element: "link", properties: { href: url, rel: "stylesheet" } },
    js: { element: "script", properties: { async: true, src: url } },
  };

  const c = configuration[filetype[1] as ResourceType];
  c.properties = { ...c.properties, ...properties };

  return new Promise<HTMLElement>((resolve, reject) => {
    const element = document.createElement(c.element) as HTMLElement;
    for (const property in c.properties) {
      if (Object.prototype.hasOwnProperty.call(c.properties, property)) {
        const value = c.properties[property];
        if (property in element) {
          (element as any)[property] = value;
        } else {
          element.setAttribute(property, value);
        }
      }
    }

    if (text) element.appendChild(document.createTextNode(text));

    element.addEventListener("load", () => resolve(element), false);
    element.addEventListener(
      "error",
      () =>
        reject(new Error(`Unable to load element, check "${url}" for errors.`)),
      false,
    );

    document.body.appendChild(element);
  });
}

/**
 * Load resource without any additional conditions.
 *
 * @param url - Resource URL to load
 * @returns Returns with HTMLElement or Error
 */
export function loadSimpleResource(url: string): Promise<HTMLElement> {
  return loadResource(url);
}

/**
 * Loads multiple resources like CSS or JS files into the webpage.
 * If you need additional attributes or inner text, please use `loadResource()`.
 *
 * @param urls - Resource URLs (CSS, JS) to load
 * @returns Returns with list of HTMLElement or Error
 */
export function loadResources(...urls: string[]): Promise<HTMLElement[]> {
  if (urls.length === 0) {
    return Promise.reject(
      new Error(
        'Missing "urls" parameter, try something like `loadResources("https://code.jquery.com/jquery-3.3.1.min.js")`.',
      ),
    );
  }
  return Promise.all(urls.map(loadSimpleResource));
}

export function parseDuration(duration: string): number {
  if (!duration.startsWith("PT")) {
    throw new Error("Invalid duration format");
  }
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const matches = duration.match(regex);
  if (!matches) {
    throw new Error("Invalid duration format");
  }
  const [_, h, m, s] = matches;
  return (
    parseInt(h || "0") * 3600 + parseInt(m || "0") * 60 + parseInt(s || "0")
  );
}
