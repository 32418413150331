import {
  type AdvertisingConfig,
  type LoaderConfig,
  type MediaConfig,
} from "@foundation-player/loader";
import type { PluginConfig } from "@foundation-player/plugin-advertising";
import { Plugin } from "./media_plugin.js";
import { loadResource, parseDuration } from "./utils.js";
import { SmartclipConfig } from "./media_player.types";

function createAdCallUrl(
  offset: number,
  targeting: string,
  durationInSeconds: number,
  zone: string,
  userPlus: boolean,
): string {
  const adCallUrl = new URL("https://ad-ipd.sxp.smartclip.net/select");
  const vdcParameter = new URLSearchParams(targeting);

  adCallUrl.searchParams.set("ang_conlen", durationInSeconds.toString());
  adCallUrl.searchParams.set("ang_ref", window.location.href);
  adCallUrl.searchParams.set(
    "fwd_cf",
    durationInSeconds < 600 ? "short_form" : "long_form",
  );
  adCallUrl.searchParams.set("fwd_vpaid", "1");
  adCallUrl.searchParams.set("rnd", new Date().getTime().toString());
  adCallUrl.searchParams.set("s", `ems_stern${zone ? "/" + zone : ""}`);
  adCallUrl.searchParams.set("sz", offset === 0 ? "pre" : "post");
  adCallUrl.searchParams.set("type", "vast4");

  vdcParameter.forEach((value, key) => {
    adCallUrl.searchParams.set(key, value);
  });

  if (userPlus) {
    adCallUrl.searchParams.set("fwd_sternplus", "1");
  }

  let urlString = adCallUrl.href;
  urlString += "&ang_plrh={PLAYER_HEIGHT}";
  urlString += "&ang_plrw={PLAYER_WIDTH}";
  urlString += "&consent={CONSENT}";
  urlString += "&fwd_adb=[BLOCKED]";

  return urlString;
}

/**
 * For further information on the advertising plugin, please also refer
 * to the corresponding API documentation in the Foundation Player documentation:
 *
 * @see https://docs.player.foundation/v1/integration/available-plugins#advertising
 * @see https://docs.player.tvnow.de/interfaces/AdvertisingPluginConfig.html
 */
export class PluginAdvertising extends Plugin {
  readonly #vccUrl =
    "https://bilder-a.akamaihd.net/ip/vcc/vcc.min.js?site=stern";

  readonly #homadClientConfig = {
    alias: "desternadsplayerfoundation",
    config: "https://hgc-cf-cache-1.svonm.com/www.stern.de/config.json",
    enabled: true,
    server: [
      "https://ssl.1.damoh.aws.stern.de/[hash]/",
      "https://ssl.2.damoh.aws.stern.de/[hash]/",
      "https://ssl.3.damoh.aws.stern.de/[hash]/",
    ],
  };

  public constructor(dataset: DOMStringMap) {
    super(dataset);
  }

  /**
   * Checks zone of MoltenBundle, because it may differ from the zone set by us.
   */
  static #areAdsAllowedByMoltenBundle(): boolean {
    const adZone = window.MoltenBundle?.getClient().getZone();
    return adZone !== undefined && adZone !== "_noad";
  }

  public isEnabled(): boolean {
    return this.dataset.disableAds !== "true";
  }

  public async addFoundationLoaderConfig(): Promise<
    LoaderConfig["pluginConfigs"] | undefined
  > {
    return new Promise((resolve) => {
      // Add video code to start of MoltenBundle cmd by `unshift` to have it executed first
      window.MoltenBundle?.cmd.unshift(() => {
        if (!PluginAdvertising.#areAdsAllowedByMoltenBundle()) {
          resolve(undefined);
        }
        this.#getFoundationPluginAdvertisingConfig().then(
          (config) => {
            if (config) {
              resolve({ "foundation.plugin.advertising": config });
            }
          },
          () => {
            resolve(undefined);
          },
        );
      });
    });
  }

  public async addFoundationMediaConfig(): Promise<
    Partial<MediaConfig> | undefined
  > {
    // Receive targeting parameter once for all possible adslots. This is done here to call
    // Moltens getSXPKeysAsync function only once.

    const foundationConfig: AdvertisingConfig = {
      slots: [],
    };

    const duration = parseDuration(this.dataset.length!);

    const setupAds = (targeting: string) => {
      if (
        duration > 30 &&
        this.dataset.userNoad !== "true" &&
        this.dataset.zone !== "_noad"
      ) {
        foundationConfig.slots.push(
          {
            type: "LINEAR",
            offset: 0,
            adCall: createAdCallUrl(
              0,
              targeting,
              duration,
              this.dataset.zone!,
              this.dataset.userPlus === "true",
            ),
          },
          {
            type: "LINEAR",
            offset: -1,
            adCall: createAdCallUrl(
              -1,
              targeting,
              duration,
              this.dataset.zone!,
              this.dataset.userPlus === "true",
            ),
          },
        );
      }
    };

    const defaultTargetParams = "&fwd_j4=1:2&fwd_j5=2:3:4";

    const getSXPKeysWithTimeout = (): Promise<string> => {
      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          resolve(defaultTargetParams);
        }, 3000);

        window.MoltenBundle.Video.getSXPKeysAsync()
          .then((targeting: string) => {
            clearTimeout(timeout);
            resolve(targeting);
          })
          .catch(() => {
            clearTimeout(timeout);
            resolve(defaultTargetParams);
          });
      });
    };

    if (window.MoltenBundle) {
      if (window.MoltenBundle.Video) {
        window.MoltenBundle.Video.setTargeting(
          "vcd",
          parseDuration(this.dataset.length!),
        );
        const targeting = await getSXPKeysWithTimeout();
        setupAds(targeting);
      } else {
        await new Promise<void>((resolve) => {
          window.addEventListener("AC_ready", async () => {
            window.MoltenBundle.Video.setTargeting(
              "vcd",
              parseDuration(this.dataset.length!),
            );
            const targeting = await getSXPKeysWithTimeout();
            setupAds(targeting);
            resolve();
          });
        });
      }
    }

    return { advertising: foundationConfig };
  }

  #getSmartclipConfig(): SmartclipConfig | undefined {
    if (!window.videoCreativeController) {
      console.error(
        "Foundation Player",
        "window.videoCreativeController script is not available!",
      );
      return undefined;
    }

    const smartclipConfig: SmartclipConfig =
      window.videoCreativeController.getSmartClientCoreAdvertisingConfig();

    // Partially replace homad config from VCC by tenant specific values
    if (smartclipConfig.adReinsertion) {
      smartclipConfig.adReinsertion.homad.setup.activationCallback = async (
        reason: number,
      ): Promise<void> => {
        return new Promise((resolve) => {
          console.info(
            "Foundation Player",
            "Homad activated by reason: ",
            reason,
          );
          resolve();
        });
      };
      // @ts-expect-error value not assignable to field type string
      smartclipConfig.adReinsertion.homad.setup.clientConfig =
        this.#homadClientConfig;
      smartclipConfig.adReinsertion.homad.setup.globalConfig =
        "https://s3.amazonaws.com/homad-global-configs.schneevonmorgen.com/global_config.json";
    } else {
      // Delete adReinsertion, if homad is disabled by tenant config.
      delete smartclipConfig.adReinsertion;
    }

    return smartclipConfig;
  }

  async #getFoundationPluginAdvertisingConfig(): Promise<
    PluginConfig | undefined
  > {
    await loadResource(this.#vccUrl);
    if (!window.videoCreativeController) {
      console.error(
        "Foundation Player",
        "window.videoCreativeController is not available!",
      );
      return undefined;
    }
    const qualitySettings = window.videoCreativeController.getQualitySettings();
    return {
      qualitySettings,
      smartclipConfig: this.#getSmartclipConfig() as Record<string, unknown>,
    };
  }
}

declare global {
  interface Window {
    MoltenBundle: any;
    videoCreativeController: any;
  }
}
