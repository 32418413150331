import type { PlayerLoaderConfig, UIConfig } from "@foundation-player/ui";
import {
  Environment,
  type LoaderConfig,
  LogLevel,
  type Manifest,
  type ManifestType,
  type MediaConfig,
  Platform,
  SourceType,
} from "@foundation-player/loader";
import type { Config } from "./media_player.types.js";
import type { Plugin } from "./media_plugin.js";
import type { Player } from "./media_player.js";
import { PluginConfig } from "@foundation-player/plugin-advertising";
import { parseDuration } from "./utils";

function mergeObjects<TObject extends object, TSource extends object>(
  target: TObject,
  source: TSource,
): TObject & TSource {
  if (!isObject(target) || !isObject(source)) {
    return source as TObject & TSource;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = (target as any)[key];
    const sourceValue = (source as any)[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      // Merge arrays by concatenation
      (target as any)[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      // Recursively merge nested objects
      (target as any)[key] = mergeObjects(targetValue, sourceValue);
    } else {
      // Direct assignment for other types
      (target as any)[key] = sourceValue;
    }
  });

  return target as TObject & TSource;
}

function isObject(value: any): value is object {
  return (
    value &&
    typeof value === "object" &&
    !Array.isArray(value) &&
    !(value instanceof Date)
  );
}

/**
 * Base class of configurations for Publishing Player. Must be extended by
 * classes for video and audio source.
 */
export abstract class PlayerConfig implements Config {
  protected readonly dataset: DOMStringMap;
  protected doAutoplay = false;
  protected abstract uiMode: "audio" | "video";

  protected constructor(dataset: DOMStringMap) {
    this.dataset = dataset;
  }

  public static async getLoaderConfig(
    plugins: Set<Plugin>,
  ): Promise<PlayerLoaderConfig> {
    const loaderConfig: PlayerLoaderConfig = {
      client: "rtldigitalnews",
      environment: Environment.Production,
      platform: Platform.Web,
      playerConfig: {
        autoplay: false,
        loggerConfig: {
          "*": LogLevel.Debug,
        },
      },
      pluginConfigs: {},
      scope: "stern",
    };

    // Merge loaderConfig from plugins
    await Promise.all(
      [...plugins].map(async (plugin) => {
        try {
          if (plugin.addFoundationLoaderConfig && plugin.isEnabled()) {
            mergeObjects(
              loaderConfig.pluginConfigs as Record<string, PluginConfig>,
              (await plugin.addFoundationLoaderConfig()) as LoaderConfig,
            );
          }
        } catch (error) {
          console.error("Error in plugin.addFoundationLoaderConfig:", error);
        }
      }),
    );
    console.info("Foundation Player", "LoaderConfig:", loaderConfig);

    return loaderConfig;
  }

  public getUiConfig(): UIConfig {
    if (this.dataset.headline === undefined) {
      throw new Error("Required data attribute 'headline' is missing.");
    }

    const uiConfig: UIConfig = {
      allowNativeVideoFullscreen: true,
      createPlayerOnDemand: !this.doAutoplay,
      durationInSeconds: this.dataset.length
        ? parseDuration(this.dataset.length)
        : undefined,
      headline: this.dataset.headline,
      mode: this.uiMode,
      poster: this.dataset.poster ?? "",
      secondaryHeadline: this.dataset.kicker ?? "",
      theme: "dark",
    };
    console.info("Foundation Player", "UiConfig:", uiConfig);

    return uiConfig;
  }

  public async getMediaConfig(player: Player): Promise<MediaConfig> {
    const mediaConfig: MediaConfig = {
      // Property "autoplay" must always be true here.
      // Non-autoplay is handled by property "createPlayerOnDemand" in getUiConfig()
      // @see https://docs.player.tvnow.de/interfaces/UIConfig.html#createPlayerOnDemand
      autoplay: true,
      id: this.dataset.id,
      manifests: this.createManifestsFromData(),
      metadata: {
        userStarted: !this.doAutoplay,
      },
      type: this.dataset.type === "audio" ? SourceType.Podcast : SourceType.VOD,
    };

    // Merge mediaConfig from plugins into basic mediaConfig
    await Promise.all(
      [...player.plugins].map(async (plugin) => {
        if (plugin.addFoundationMediaConfig && plugin.isEnabled()) {
          mergeObjects(
            mediaConfig,
            (await plugin.addFoundationMediaConfig(
              player,
            )) as Promise<MediaConfig>,
          );
        }
      }),
    );
    console.info("Foundation Player", "MediaConfig:", mediaConfig);

    return mediaConfig;
  }

  /**
   * Create manifests for Publishing Player from manifests in data-renditions attribute
   */
  protected createManifestsFromData(): Manifest[] {
    const publishingPlayerManifests: Manifest[] = [];
    if (this.dataset.renditions === undefined) {
      return publishingPlayerManifests;
    }

    const manifests = JSON.parse(this.dataset.renditions) as Record<
      string,
      { url: string }
    >;
    for (const type in manifests) {
      if (!Object.hasOwn(manifests, type)) {
        continue;
      }
      let manifestType: ManifestType | null = null;
      switch (type) {
        case "hls":
          manifestType = "hlsfairplayhd";
          break;
        case "dash":
          manifestType = "dashhd";
          break;
        case "progressive":
          manifestType = "progressive";
          break;
      }

      if (manifestType === null) {
        continue;
      }

      const manifestSources: Manifest["sources"] = [];
      manifestSources.push({
        priority: "main",
        url: manifests[type]?.url ?? "",
      });
      publishingPlayerManifests.push({
        sources: manifestSources,
        type: manifestType,
      });
    }

    return publishingPlayerManifests;
  }
}
