import { AudioPlayer } from "./media_audio.js";
import { VideoPlayer } from "./media_video.js";
import { PlayerElement } from "@foundation-player/loader";

type Mode = "audio" | "video";

export const INIT = "initialize";

const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host {
      display: block;
    }
    .player-container {
      position: relative;
    }
  </style>
  <div class="player-container"></div>
`;

export class MediaPlayer extends HTMLElement {
  player: PlayerElement | HTMLMediaElement | undefined;
  #playerContainer: HTMLElement | undefined;

  get userPlus(): boolean {
    return this.dataset.userPlus === "true";
  }

  get freeAccessibleSeconds(): number | undefined {
    const freeAccSec = this.dataset.freeAccessibleSeconds;
    const parsed = parseInt(freeAccSec ?? "", 10);
    return isNaN(parsed) ? undefined : parsed;
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
  }

  #getPlayerComponent(mode: Mode) {
    return {
      audio: AudioPlayer,
      video: VideoPlayer,
    }[mode];
  }

  connectedCallback() {
    this.#playerContainer = this.shadowRoot!.querySelector(
      ".player-container",
    )! as HTMLElement;
    this.addEventListener(INIT, this.#cleanUpElements);
    Object.assign(this.#playerContainer.dataset, this.dataset);
    (async () => {
      await this.#setupMediaElement();
      this.dispatchEvent(new CustomEvent(INIT));
    })();
  }

  #ensureSingularPlayingInstance() {
    if (this.dataset.mode === "audio") {
      this.player!.addEventListener("player:Play", () => {
        const otherPlayers = Array.from(document.querySelectorAll("ws-media"))
          .map(({ player }) => player)
          .filter(Boolean)
          .filter((player) => player !== this.player);
        otherPlayers.forEach((player) => {
          (player as HTMLMediaElement).pause();
        });
      });
    }
  }

  #handleFreeAccessibleSeconds() {
    this.player!.addEventListener("player:ContentStart", () => {
      if (
        typeof this.freeAccessibleSeconds === "number" &&
        !Number.isNaN(this.freeAccessibleSeconds)
      ) {
        this.player!.addEventListener("player:TimeChanged", () => {
          const currentTime = (this.player as any)!.getCurrentTime();
          if (currentTime > this.freeAccessibleSeconds! && !this.userPlus) {
            const dialog = document.querySelector("dialog");
            if (dialog) {
              (this.player as HTMLMediaElement)!.pause();
              dialog.showModal();
            }
          }
        });
      }
    });
  }

  async #setupMediaElement() {
    const PlayerComponent = this.#getPlayerComponent(this.dataset.mode as Mode);
    await new PlayerComponent(this.#playerContainer!)
      .init()
      .catch((reason: unknown) => {
        console.error("Foundation Player", "Error on player:", reason);
      });
    this.player = await (this.shadowRoot!.querySelector(
      "[data-foundation-player]",
    ) as any)!.getPlayer();

    this.#ensureSingularPlayingInstance();

    this.#handleFreeAccessibleSeconds();
  }

  disconnectedCallback() {
    this.removeEventListener(INIT, this.#cleanUpElements);
  }

  #cleanUpElements() {
    this.querySelector("figure")?.remove();
  }
}

customElements.get("ws-media") ??
  customElements.define("ws-media", MediaPlayer);

declare global {
  interface HTMLElementTagNameMap {
    "ws-media": MediaPlayer;
  }

  interface Window {
    MoltenBundle: any;
  }
}
