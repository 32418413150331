import type {
  LoaderConfig,
  MediaConfig,
  MediaPluginConfigs,
} from "@foundation-player/loader";
import { Plugin } from "./media_plugin.js";

interface TrackingData {
  firstPublicationDate?: string;
  hiddenSources?: string[][];
  sources?: string[][];
  staticTitle?: string;
  technicalTags?: string[];
}

/**
 * For further information on the Google Analytics plugin, please also refer
 * to the corresponding API documentation in the Foundation Player documentation:
 *
 * @see https://docs.player.foundation/v1/integration/available-plugins#google-analytics
 * @see https://docs.player.tvnow.de/interfaces/GoogleAnalytics4PluginConfig.html
 * @see https://docs.player.tvnow.de/interfaces/GoogleAnalytics4MediaPluginConfig.html
 */
export class PluginGa4 extends Plugin {
  // see EventTimeUpdate.#interval
  readonly #interval = 10;
  readonly #playerType: "audio" | "video";

  public constructor(dataset: DOMStringMap, playerType: "audio" | "video") {
    super(dataset);
    this.#playerType = playerType;
  }

  public isEnabled(): boolean {
    return true;
  }

  public addFoundationLoaderConfig(): LoaderConfig["pluginConfigs"] {
    return {
      "foundation.plugin.googleAnalytics4": {
        enabled: true,
        progressTrackingIntervalInSeconds: this.#interval,
      },
    };
  }

  public addFoundationMediaConfig(): Partial<MediaConfig> {
    const trackingData: TrackingData =
      typeof this.dataset.tracking === "string"
        ? (JSON.parse(this.dataset.tracking) as TrackingData)
        : {};
    const googleAnalytics4Config: MediaPluginConfigs["foundation.plugin.googleAnalytics4"] =
      {
        contentType: this.#playerType,
        firstHeadline: trackingData.staticTitle,
        headline: this.dataset.headline,
        id: this.dataset.id,
        // Since we have no real lastPublicationDate (or lastModified) for videos in feed response,
        // we use firstPublicationDate here.
        // This is the way it was also used in former Publishing Player.
        lastPublicationDate: trackingData.firstPublicationDate,
      };

    if (trackingData.technicalTags) {
      googleAnalytics4Config.tags = trackingData.technicalTags;
    }

    if (trackingData.hiddenSources) {
      googleAnalytics4Config.editorialSources = trackingData.hiddenSources.map(
        (sources: string[]) => sources.join(),
      );
    }

    return {
      plugins: {
        "foundation.plugin.googleAnalytics4": googleAnalytics4Config,
      },
    };
  }
}
