import { PlayerConfig } from "./media_config.js";

export class VideoConfig extends PlayerConfig {
  protected readonly uiMode = "video";

  public constructor(dataset: DOMStringMap) {
    super(dataset);
    this.doAutoplay = typeof this.dataset.isLeadContent !== "undefined";
  }
}
