import type { Config } from "./media_player.types.js";
import type {
  FoundationError,
  MediaConfig,
  PlayerInterface,
} from "@foundation-player/loader";
import { createPlayerUI } from "@foundation-player/ui";
import { PlayerConfig } from "./media_config.js";
import type { Plugin } from "./media_plugin.js";

/**
 * Player class initializes all video players on current page
 */
export abstract class Player {
  public foundationPlayer: PlayerInterface | undefined;
  public plugins = new Set<Plugin>();
  protected readonly element: HTMLElement;

  /**
   * Player config depends on media type (audio, video) and is defined in appropriate
   * subclasses
   */
  protected abstract readonly config: Config;

  protected constructor(element: HTMLElement) {
    this.element = element;
    this.registerPlugins();
  }

  public async init(): Promise<void> {
    try {
      this.foundationPlayer = await createPlayerUI(
        this.element,
        await PlayerConfig.getLoaderConfig(this.plugins),
        this.config.getUiConfig(),
      );

      // Execute addEventListener method of all plugins
      this.plugins.forEach((plugin) => {
        if (plugin.addEventListener && plugin.isEnabled()) {
          plugin.addEventListener(this.foundationPlayer!);
        }
      });

      const sourcepoint = document.querySelector(
        "ws-sourcepoint",
      ) as HTMLElement | null;

      const getConsent = async (): Promise<string> => {
        if (sourcepoint) {
          await window.customElements.whenDefined("ws-sourcepoint");
          // Adjust the type as needed
          return await (sourcepoint as any).getTcString();
        }
        return ""; // Fallback if sourcepoint is not present
      };

      // Set consent, when ready
      this.foundationPlayer.setConsentCallback(getConsent);

      // Load media
      const mediaConfig: MediaConfig = await this.config.getMediaConfig(this);

      await this.foundationPlayer
        .loadMedia(mediaConfig)
        .catch(async (error: unknown) => {
          await this.foundationPlayer!.destroy(error as FoundationError);
        });
    } catch (error) {
      console.error("Foundation Player", "Error creating config:", error);
    }
  }

  /**
   * Register all plugins relevant for current player mode ("video", "audio").
   * There are no common plugins configured in parent class "Player" to have all plugins set
   * at one place.
   */
  //
  protected abstract registerPlugins(): void;
}
