import { PlayerConfig } from "./media_config.js";

export class AudioConfig extends PlayerConfig {
  protected readonly uiMode = "audio";

  public constructor(dataset: DOMStringMap) {
    super(dataset);
    this.doAutoplay = typeof this.dataset.isTextToSpeech !== "undefined";
  }
}
