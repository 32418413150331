import type { MediaPlayer } from "./media";

const playerTemplate = document.createElement("template");

playerTemplate.innerHTML = `<ws-media
  data-disable-ads="false"
  data-is-text-to-speech="true"
  data-mode="audio"
  data-type="audio"
  data-user-noad="false"
  data-user-plus="false"></ws-media>`;

export const buttonIconClass = "icon-headphones";
export const buttonIconClassActive = "icon-close";
export const buttonTextValue = "Artikel anhören";
export const buttonTextValueActive = "Player schließen";

export class TextToSpeech extends HTMLElement {
  #button: HTMLButtonElement | null = null;
  #buttonText: HTMLSpanElement | null = null;
  #buttonIcon: HTMLElement | null = null;
  #playerContainer: HTMLDivElement | null = null;
  #player: HTMLElement | null = null;

  get loaded(): boolean {
    return this.hasAttribute("loaded");
  }

  set loaded(value) {
    value ? this.setAttribute("loaded", "") : this.removeAttribute("loaded");
  }

  get active(): boolean {
    return this.hasAttribute("active");
  }

  set active(value) {
    value ? this.setAttribute("active", "") : this.removeAttribute("active");
  }

  connectedCallback() {
    this.#init();
  }

  #init() {
    this.#button = this.querySelector("button");
    this.#buttonText = this.querySelector("button span");
    this.#buttonIcon = this.querySelector("button i");
    this.#playerContainer = document.querySelector(
      ".feature-bar__player-container",
    );

    if (
      this.#button &&
      this.#buttonText &&
      this.#buttonIcon &&
      this.#playerContainer
    ) {
      this.#button.addEventListener(
        "click",
        this.#handleButtonClick.bind(this),
      );
    }
  }

  #handleButtonClick() {
    if (!this.loaded) {
      this.#loadPlayer();
    } else {
      this.#togglePlayer();
    }
  }

  #loadPlayer() {
    // load player
    this.#player = playerTemplate.content.cloneNode(true)
      .firstChild as HTMLElement;
    this.#player.setAttribute(
      "data-renditions",
      JSON.stringify({ progressive: { url: this.dataset.url } }),
    );
    this.#player.setAttribute("data-kicker", this.dataset.kicker!);
    this.#player.setAttribute("data-headline", this.dataset.headline!);
    this.#player.setAttribute("data-zone", this.dataset.zone!);
    this.#playerContainer!.appendChild(this.#player);
    this.loaded = true;
    this.active = true;
    this.#buttonText!.innerText = buttonTextValueActive;
    this.#buttonIcon!.className = buttonIconClassActive;
  }

  #togglePlayer() {
    if (!this.#player) {
      return;
    }
    if (this.active) {
      // hide player and pause audio
      this.active = false;
      this.#playerContainer!.classList.add("d-none");
      this.#buttonText!.innerText = buttonTextValue;
      this.#buttonIcon!.className = buttonIconClass;
      ((this.#player as MediaPlayer).player as HTMLMediaElement).pause();
    } else {
      // show player and start audio
      this.active = true;
      this.#playerContainer!.classList.remove("d-none");
      this.#buttonText!.innerText = buttonTextValueActive;
      this.#buttonIcon!.className = buttonIconClassActive;
      ((this.#player as MediaPlayer).player as HTMLMediaElement).play();
    }
  }
}

customElements.get("ws-texttospeech") ??
  customElements.define("ws-texttospeech", TextToSpeech);

declare global {
  interface HTMLElementTagNameMap {
    "ws-texttospeech": TextToSpeech;
  }
}
